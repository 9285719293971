import React, { useEffect, useState } from "react";
import "./about.css";
import { getData } from "../../utils/db/getCollection";

export const About = () => {
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certificates, setcertificates] = useState([]);

  useEffect(() => {
    const obtenerCertificados = async () => {
      try {
        const response = await getData("certifications");

        // Extraer solo los datos
        const certificados = response.map((doc) => ({
          id: doc.id, // Si necesitas el ID del documento
          ...doc.data(), // Obtiene solo los datos del documento
        }));

        setcertificates(certificados);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCertificados();
  }, []);

  const handleCertificateClick = (url) => {
    setSelectedCertificate(url);
  };

  return (
    <div className="page">
      <h1 className="heading">Sobre mi</h1>
      <hr />
      <h2>Experiencia Laboral</h2>
      <ul>
        <li>
          <div className="title_exp">
            <h3>Desarrollador React Native (Proyecto de Tenis) Freelance</h3>
            <h3>Actual</h3>
          </div>
          <p>
            Desarrollo de una aplicación móvil para reservar canchas de tenis,
            participar en torneos e inscribirse en clases. Implementé Firebase
            como backend, utilizando Firebase Functions cuando es necesario, y
            gestioné suscripciones y pagos a través de MercadoPago. Utilicé
            GitHub para control de versiones y React Native con Expo para el
            desarrollo de la app.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Desarrollador de Videojuegos Proyecto Personal</h3>
            <h3>2024</h3>
          </div>
          <p>
            Desarrollé un videojuego de supervivencia con Unreal Engine 4,
            optimizado para dispositivos móviles y Steam. Implementé sistemas de
            recolección de recursos, caza y construcción en un entorno 3D.
            Tecnologías: Unreal Engine 4, Blender, Google Play, Steam.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Desarrollador React Native Proyecto Personal</h3>
            <h3>2024</h3>
          </div>
          <p>
            Diseñé y publiqué una aplicación en Google Play Store con
            autenticación Firebase y notificaciones en tiempo real. Integré
            RevenueCat para monetización mediante suscripciones y AdMob para
            anuncios. Tecnologías: Expo SDK 49, Firebase, AsyncStorage, Google
            Play Services.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Ejecutivo de Ventas ECRGroup</h3>
            <h3>03/2024 — 06/2024</h3>
          </div>
          <p>
            Realicé prospección y gestión de ventas, asegurando la satisfacción
            del cliente y el cumplimiento de metas comerciales.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Desarrollador React Native Freelance</h3>
            <h3>2023</h3>
          </div>
          <p>
            Diseñé y desarrollé una aplicación móvil de belleza compatible con
            iOS y Android utilizando React Native y Firebase. Implementé una
            infraestructura robusta con Firestore Database, Authentication y
            Storage. Integré la pasarela de pagos de Transbank para procesar
            transacciones de manera segura. Tecnologías: Expo SDK 47, Firebase,
            Node.js.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Analista Desarrollador Amisoft</h3>
            <h3>05/2022 — 07/2022</h3>
          </div>
          <p>
            Participé en el desarrollo de un sistema interno para el Poder
            Judicial de Chile utilizando Java y Spring Boot. Implementé
            funcionalidades que optimizaron la gestión de demandas legales
            cumpliendo con altos estándares de seguridad y calidad.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>Desarrollador Mobile JYF Industrial</h3>
            <h3>01/2021 — 08/2021</h3>
          </div>
          <p>
            Creé una aplicación móvil personalizada para la gestión interna de
            una microempresa utilizando React Native y Expo. Implementé
            conexiones con bases de datos remotas (MySQL y SQLite) a través de
            PHP para garantizar la sincronización de datos. Adapté la aplicación
            para compatibilidad en plataformas iOS y Android.
          </p>
        </li>
      </ul>
      <br />
      <hr />
      <h2>Educación</h2>

      <ul className="education">
        <li>
          <span>2017 – 2020</span>
          <span>Ingeniería en Informática</span>
          <span>INACAP</span>
        </li>

        <li>
          <span>2024 – Actual</span>
          <span>Full Stack JavaScript</span>
          <span>Desafío Latam</span>
        </li>
      </ul>
      <br />
      <hr />
      <h2>Certificados</h2>
      <ul className="certificates">
        {certificates.map((cert, index) => (
          <li key={index}>
            <a
              href={selectedCertificate}
              onClick={() => handleCertificateClick(cert.url)}
              target="_blank" // Abre en una nueva pestaña
            >
              {cert.title}
            </a>
          </li>
        ))}
      </ul>

      <br />
      <hr />
      <h2>Cursos</h2>
      <ul className="education">
        <li>
          <span>2023 – 2024</span>
          <span>Aplicación React Native – Node.js Express</span>
          <span>Udemy</span>
        </li>
        <li>
          <span>2024 – 2024</span>
          <span>Ecommerce React – Next.js – Strapi</span>
          <span>Udemy</span>
        </li>
      </ul>
    </div>
  );
};
