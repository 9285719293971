import React, { useState } from "react";
import "./contacto.css";
import emailjs from "emailjs-com"; // Importa emailjs

export const Contacto = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    mensaje: "",
  });
  const [status, setStatus] = useState(""); // Para mostrar el estado de envío

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Crea un objeto con los datos del formulario
    const formValues = {
      nombre: formData.nombre,
      apellido: formData.apellido,
      correo: formData.correo, // Este es el correo del remitente
      mensaje: formData.mensaje,
      from_email: formData.correo, // Pasa el correo del remitente aquí
    };

    emailjs
      .sendForm(
        "service_we3ur29", // Reemplaza con tu service_id de EmailJS
        "template_0p8bczc", // Reemplaza con tu template_id de EmailJS
        formValues,
        "PNGH0i8bgaiUWxA4C" // Reemplaza con tu user_id de EmailJS
      )
      .then(
        (result) => {
          setStatus("¡Mensaje enviado con éxito!");
          setFormData({
            nombre: "",
            apellido: "",
            correo: "",
            mensaje: "",
          });
        },
        (error) => {
          setStatus("Error al enviar el mensaje, intenta nuevamente.");
        }
      );
  };

  return (
    <div className="page">
      <h1 className="heading">Contacto</h1>
      <form className="contact" onSubmit={handleSubmit}>
        <input
          type="text"
          name="nombre"
          placeholder="Nombre"
          value={formData.nombre}
          onChange={handleChange}
        />
        <input
          type="text"
          name="apellido"
          placeholder="Apellido"
          value={formData.apellido}
          onChange={handleChange}
        />
        <input
          type="text"
          name="correo"
          placeholder="Correo electrónico"
          value={formData.correo}
          onChange={handleChange}
        />
        <textarea
          name="mensaje"
          placeholder="Motivo de contacto"
          value={formData.mensaje}
          onChange={handleChange}
        />
        <input type="submit" value="Enviar" />
      </form>
      {status && <p>{status}</p>} {/* Muestra el estado de envío */}
    </div>
  );
};
